
import axios from '@/api/config/interceptor'
// import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'
const host = 'https://vapi.gts56.com/gybdemo'
// 获取围栏列表
export const GetFenceList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ElectronicFence/List`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 编辑围栏
export const editFence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ElectronicFence/Edit`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 删除围栏
export const deleteFence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ElectronicFence/Del?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const convertPosition = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ElectronicFence/ReverseCoordTX?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}