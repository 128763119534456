<template>
  <div class="fence">
    
      <div id="container" style="width:auto;height:100vh;"></div>


    <div class="box">
      <div class="info">

        <div class="item">
          <div class="name">围栏名称</div>
          <van-field input-align="right" disabled maxlength="15" v-model="fenceForm.fenceName" placeholder="请输入围栏名称" />
        </div>
        <div class="item">
          <div class="name">圆心位置</div>

          <van-field input-align="right" disabled v-model="fenceForm.fenceCenterLocation" placeholder="请输入围栏名称" />
        </div>
        <div class="item">
          <div class="name">圆心纬度</div>
          <van-field input-align="right" disabled v-model="fenceForm.latitude" type="number" placeholder="请输入圆心纬度" />
        </div>
        <div class="item">
          <div class="name">圆心经度</div>
         <van-field input-align="right" disabled v-model="fenceForm.longitude" type="number" placeholder="请输入圆心经度" />
        </div>
        <div class="item">
          <div class="name">圆心半径(米)</div>
         <van-field input-align="right" v-model="fenceForm.radius" type="number" placeholder="请输入圆心半径" />
        </div>
   
      </div>

      
   
    </div>
    <div class="btns">
        <div class="btn1" @click="addCircle">创建</div>
        <div class="btn2" @click="submitFence">确定</div>
        <div class="btn3" @click="removeCircle">删除</div>
      </div>
  </div>
</template>

<script>
import {
  editFence,
  deleteFence,
  convertPosition
} from '@/api/GYBapi/electronic'
import { Toast } from 'vant';
import { Field } from 'vant';
export default {
  data() {
    return {
      type: 1, // 控制 是否已经画了围栏，2是已经画了 1是没画
      map: null, // 地图
      // marker: null, // 标注点
      center: new TMap.LatLng(28.606169, 115.934794),
      editor: null,
      circles: null,
      fenceForm: {
        fenceId: '',
        fenceName: '',
        fenceCenterLocation: '',
        longitude: null,
        latitude: null,
        radius: null,
        userId: '',
        jobTaskId: ''
      },
    
    };
  },
  computed: {
   
  },
  methods: {
    initMap() { // 地图初始化
      let center = null
      if(this.fenceForm.longitude) {
        center = new TMap.LatLng(this.fenceForm.latitude, this.fenceForm.longitude)
      } else {
        center = this.center
      }
      this.map = new TMap.Map("container", {
        // center:new TMap.LatLng(this.fenceForm.latitude, this.fenceForm.longitude),
        center,
      });
      // 围栏数据
      this.circles = new TMap.MultiCircle({
        map: this.map,
        styles: {
          'circle': new TMap.CircleStyle({
            'color': 'rgba(41,91,255,0.16)',
            'showBorder': true,
            'borderColor': 'rgba(41,91,255,1)',
            'borderWidth': 2,
          })
        },
        geometries: [],
      })
    },
    load() { // 围栏数据初始化
      this.handleGetData()
      
    },
 
    handleGetData() { // 获取围栏数据

      this.circles.add({
              styleId: 'circle',
              center: new TMap.LatLng(this.fenceForm.latitude, this.fenceForm.longitude),
              radius: this.fenceForm.radius
            })
           
    },
   
    addCircle() {// 新增围栏
    if(this.type == 2) {
       Toast('您已绘制围栏，请勿重复绘制');
       return
    }
      this.editor = new TMap.tools.GeometryEditor({
        map: this.map, // 编辑器绑定的地图对象
        overlayList: [
          {
            overlay: new TMap.MultiCircle({
              map: this.map
            }),
            id: 'circle'
          }
        ],
        actionMode: TMap.tools.constants.EDITOR_ACTION.DRAW, // 编辑器的工作模式
        activeOverlayId: 'marker', // 激活图层
        selectable: true, // 开启点选功能
        snappable: true // 开启吸附
      });
      this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW);
      this.editor.setActiveOverlay('circle')
      // 新增围栏绘制成功
      this.editor.on('draw_complete', (geometry) => {
          this.fenceForm.longitude = geometry.center.lng
          this.fenceForm.latitude = geometry.center.lat
          this.fenceForm.radius = geometry.radius
          let site = {
            lat: this.fenceForm.latitude,
            lng: this.fenceForm.longitude
          }
           convertPosition(site).then(res => {
              this.fenceForm.fenceCenterLocation = res.data.address
        })
        this.type = 2
        this.editor.destroy()
      });
    },
    // 确定提交围栏
    submitFence() {

      if(!this.fenceForm.fenceId) {
        Toast('请选择围栏');
        return
      }
      if(!this.fenceForm.fenceName) {
        Toast('请输入围栏名称');
        return
      }
      if(!this.fenceForm.fenceCenterLocation) {
        Toast('请输入圆心位置');
        return
      }
      if(!this.fenceForm.longitude) {
        Toast('请绘制围栏');
        return
      }
      if(!this.fenceForm.radius) {
        Toast('请输入围栏半径');
        return
      }
      let params = {
        fenceID: this.fenceForm.fenceId,
        fenceName: this.fenceForm.fenceName,
        longitude: this.fenceForm.longitude,
        latitude: this.fenceForm.latitude,
        fenceCenterLocation: this.fenceForm.fenceCenterLocation,
        radius: this.fenceForm.radius,
        jobTaskId: this.fenceForm.jobTaskId,
        userId: this.fenceForm.userId
      }
      editFence(params).then(res => {
        // this.fenceForm.fenceId = res.data.data.FenceID
        Toast('设置围栏成功');
        // this.fenceForm
         this.type = 2
      })
    },
    removeCircle() { // 删除
      let params = {
        fenceIds: this.fenceForm.fenceId,
      }
      deleteFence(params).then(res => {
        Toast('删除围栏成功');
       
        this.fenceForm.fenceCenterLocation = ''
        // this.fenceForm.fenceId = ''
        this.fenceForm.longitude = ''
        this.fenceForm.latitude = ''
        this.fenceForm.radius = ''
        this.map.destroy()
          this.initMap();
         this.type = 1
      })
    },
  },
  mounted() {
    let data = this.$route.query
    this.fenceForm.jobTaskId = data.taskId
    this.fenceForm.userId = data.userId
    let info = {}
    // 有info 说明是编辑围栏
   if(this.$route.query.data) {
     info = JSON.parse(this.$route.query.data)
     this.fenceForm.fenceName = info.FenceName
     this.fenceForm.fenceCenterLocation = info.FenceCenterLocation
     this.fenceForm.fenceId =info.FenceID
      this.fenceForm.longitude = Number(info.Longitude)
      this.fenceForm.latitude =  Number(info.Latitude)
      this.fenceForm.radius =  Number(info.Radius)
      //   this.fenceForm.longitude = 116.00291279188718
      // this.fenceForm.latitude =  28.66918306507491
      // this.fenceForm.radius =  1125.2742388388087
      
      if(this.fenceForm.longitude) {
        this.type = 2
      }
    }
     this.initMap()
      this.load();
    
  },
}
</script>

<style  lang="scss" scoped>

.fence {
.box {
  position: fixed;
  left: 4% /* 30/16 */ /* 30/16 */;
  bottom: 6.25rem /* 100/16 */;
  background: #fff;
  width: 92%;
  z-index: 1000;
  border-radius: .25rem /* 4/16 */;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f7f7f7;
  margin: 0 1rem /* 16/16 */;
}
.name {
  white-space: nowrap;
  font-size: 1rem /* 24/16 */;
  color: #999;
}
.btns {
   position: fixed;
  left: 4% /* 30/16 */ /* 30/16 */;
  bottom: 2.25rem /* 100/16 */;
  background: #fff;
  width: 92%;
  z-index: 1000;
  border-radius: .25rem /* 4/16 */;
  display: flex;
  padding: .5rem 1rem/* 16/16 */;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.btn1 {
  width: 3.25rem /* 100/16 */;
  font-size: 1rem /* 24/16 */;
  color: #0F5FFF;
  border: .0625rem /* 1/16 */ solid #0F5FFF;
  padding: .225rem 0 /* 10/16 */;
  font-weight: 400;
  border-radius: .25rem /* 4/16 */;
  text-align: center;
}
.btn2 {
  width: 3.25rem /* 100/16 */;
  font-size: 1rem /* 24/16 */;
  color: #0F5FFF;
  border: .0625rem /* 1/16 */ solid #0F5FFF;
  padding: .225rem 0 /* 10/16 */;
  margin: 0 1.875rem /* 30/16 */;
  border-radius: .25rem /* 4/16 */;
  text-align: center;
}
.btn3 {
  width: 3.25rem /* 100/16 */;
  font-size: 1rem /* 24/16 */;
  color: #FF2B2B;
  border: .0625rem /* 1/16 */ solid #FF2B2B;
  padding: .225rem 0 /* 10/16 */;
  border-radius: .25rem /* 4/16 */;
  text-align: center;
}
}

</style>